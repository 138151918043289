import React from "react"
import {Layout} from "../components/Layout/layout";
import {PageTitle} from "../components/PageTitle";
import {Alert, Button, Card, CardBody, CardHeader, CardText, Col, Container, Row} from "reactstrap";
import {FaCalendar, FaClock, FaFilePdf} from "react-icons/fa";

export default () => (
    <React.Fragment>
        <Layout pageName="registration">
            <PageTitle title="Registration"/>
            <Container>
                <Row>
                    <Col md={4} sm={6}>
                        <div className="pricing-box pricing-box-pro">
                            <div className="pricing-box-header">
                                <h2>Big Choir<br/>(SATB)</h2>
                            </div>
                            <div className="pricing-box-price">
                                <h3>$125</h3>
                            </div>
                            <div className="pricing-box-content">
                                <ul>
                                    <li><FaCalendar/>Monday evenings</li>
                                    <li><FaClock/>7:00pm to 9:00pm</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="pricing-box pricing-box-pro">
                            <div className="pricing-box-header">
                                <h2>Persephones Choir</h2>
                            </div>
                            <div className="pricing-box-price">
                                <h3>$100</h3>
                            </div>
                            <div className="pricing-box-content">
                                <ul>
                                    <li><FaCalendar/>Monday evenings</li>
                                    <li><FaClock/>5:30pm to 6:45pm</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} sm={6}>
                        <div className="pricing-box pricing-box-pro">
                            <div className="pricing-box-header">
                                <h2>Neptunes Choir</h2>
                            </div>
                            <div className="pricing-box-price">
                                <h3>$100</h3>
                            </div>
                            <div className="pricing-box-content">
                                <ul>
                                    <li><FaCalendar/>Monday evenings</li>
                                    <li><FaClock/>5:30pm to 6:45pm</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}></Col>
                    <Col md={4} sm={6}>
                        <div className="pricing-box pricing-box-pro">
                            <div className="pricing-box-header">
                                <h2>Chamber Choir<br/>(SATB)</h2>
                            </div>
                            <div className="pricing-box-price">
                                <h3>$125</h3>
                            </div>
                            <div className="pricing-box-content">
                                <ul>
                                    <li><FaCalendar/>Wednesday evenings</li>
                                    <li><FaClock/>6:15pm to 8:15pm</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}></Col>
                </Row>

                {/*<Card>*/}
                {/*    <CardHeader tag="h3" className="panel-title" style={{backgroundColor: "#a14f91"}}>*/}
                {/*        Registration Form*/}
                {/*    </CardHeader>*/}
                {/*    <CardBody>*/}
                {/*        <CardText>*/}
                {/*            Registration is now closed for the Fall 2022 term. If you are interested in*/}
                {/*            registering for the Spring 2023 term, please send an email to <a*/}
                {/*            href="mailto:whsecommunitychoir@gmail.com">*/}
                {/*            whsecommunitychoir@gmail.com*/}
                {/*        </a> stating your wishes and you will be notified when registration is open.*/}
                {/*        </CardText>*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}

                {/*<Card>*/}
                {/*    <CardHeader tag="h3" className="panel-title" style={{backgroundColor: "#a14f91"}}>Registration*/}
                {/*        Closed</CardHeader>*/}
                {/*    <CardBody>*/}
                {/*        <CardText>*/}
                {/*            Registration for our spring concert is now closed. Check back in mid-August*/}
                {/*            to register for our fall session, where we will be preparing for our Christmas concert.*/}
                {/*        </CardText>*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}

                <Card>
                    <CardHeader tag="h3" className="panel-title" style={{backgroundColor: "#a14f91"}}>
                        Registration Form
                    </CardHeader>
                    <CardBody>
                        <CardText>
                            <strong>Youth/Student Rate (aged 13-15)</strong> - registration fee for each choir is
                            $75/term.
                        </CardText>
                        <CardText>
                            <strong>Youth/Student Rate (aged 16-25)</strong> - registration fee for each choir is
                            $75/term.
                        </CardText>
                        <CardText>
                            Registration is now open for our Spring session. Registration for all choirs opens on December
                            16, 2024 - January 13, 2025. Early
                            bird registration rate is up to December 31, 2024. Please see the registration tab
                            for membership fees and registration form.
                        </CardText>
                        <CardText>
                            Weekly rehearsals for Big Choir, Persephones and Neptunes start January 6, 2025 at the
                            Whitehorse United Church, 601 Main Street. Rehearsal times remain the same.
                        </CardText>
                        <CardText>
                            Weekly rehearsals for Chamber Choir start January 8, 2025 at CSSC Mercier (Music Room), 1009
                            Lewes Blvd. Rehearsal times change to 6:15 - 8:15 pm.
                        </CardText>
                        <CardText>
                            The form can be used to register for one or all choirs. Membership fees help to cover some
                            of our expenses, such as rental of rehearsal and performance space, sheet music, and
                            accompanist fees.
                        </CardText>
                        <CardText>
                            <a
                                href="https://forms.gle/Yqen8FF6nh5cXnm27"
                                target="_blank" rel="noopener noreferrer">
                                <Button color="primary float-right">
                                    <FaFilePdf/> Complete Registration Form
                                </Button>
                            </a>
                        </CardText>
                    </CardBody>
                </Card>
            </Container>
        </Layout>
    </React.Fragment>
)
